import React  from "react";
import { Link } from "react-router-dom";
import { LuCopyright } from "react-icons/lu";
import styles from './Footer.module.css';
import { FaInstagram } from "react-icons/fa6";
import { MdOutlineFacebook } from "react-icons/md";
import { useDispatch } from "react-redux";
import { openNewsletters } from "../redux/slices/NewsletterSlice";

const Footer = () => {
    const dispatch = useDispatch();
    const handleOpenNewsletter = ()=>{
        dispatch(openNewsletters());
    }

    return (
        <div className={styles.layout}>  
                <div className={styles.logo}>
                    <LuCopyright size={12}/>
                    <p>Terror Clothing </p>
                </div>    
                <div className={styles.more}>
                    <button  onClick={handleOpenNewsletter}>NewSletter</button>
                    <Link  to={`/policy/about-cookie`}>Chính sách cookie</Link>
                    <Link  to={`/policy/privacy`}>Chính sách bảo mật</Link>
                    <Link  to={`/policy/returns`}>Chính sách đổi trả</Link>
                </div>

        </div>
    );
};

export default Footer