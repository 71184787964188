import React, {lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './assets/i18n/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import ErrorPage from "./pages/ErrorPage";
import App from './App';
import LanguageStartPage from './pages/LanguageStartPage';
import CollectionsPage from "./pages/CollectionsPage";
// import Policy from './pages/Policy';
import ProductList , { fetchData } from "./components/ProductList";
import ProductDetail , { fetchItem } from "./components/ProductDetail";
import CheckOut from './pages/Checkout';
import Loading from './components/Loading';



const Policy = lazy(()=> import('./pages/Policy'));
const Policy_Cookie = lazy(()=> import('./views/Policy.Cookie'));
const Policy_Privacy = lazy(()=> import('./views/Policy.Privacy'));
const Policy_Returns = lazy(()=> import('./views/Policy.Returns'));
const Checkout_Success = lazy(()=> import('./views/Checkout.Success'));
const Search = lazy(()=> import('./components/Search'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true, 
        element: <LanguageStartPage /> 
      },
      {
        path: 'collections',
        element: <CollectionsPage />,
        children: [
          {
            index: true,
            element: <ProductList />,
            loader: fetchData,
          },
          {
            path: ':type', 
            element: <ProductList />,
            loader: fetchData,
          },
          {
            path: ':type/:id',
            element: <ProductDetail />,
            loader: fetchItem,
          },
          {
            path: 'search', 
            element: <Search />,
          },
        ]
      },
    ]
  },
  {
    path: "/policy",
    element: <Policy />,
    children: [
      {
        path: "about-cookie",
        element: <Policy_Cookie />,
      },
      {
        path: "privacy",
        element: <Policy_Privacy />,
      },
      {
        path: "returns",
        element: <Policy_Returns />,
      }
    ]
  },
  {
    path:'checkout',
    element: <CheckOut />,
    children: [
      {
        path: 'success',
        element: <Checkout_Success />
      }
    ]
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
       <Provider store={store}>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
       </Provider>
  </React.StrictMode>
);

reportWebVitals();
