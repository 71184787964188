import React, { useState, useEffect, useRef, useCallback  } from "react";
import styles from './Checkout.module.css';
import { useTranslation } from 'react-i18next';
import {
    GetCountries,
    GetState,
    GetCity,
} from "react-country-state-city";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import  { ReactComponent as Logo } from '../assets/logo.svg';
import { useNavigate ,Outlet, ScrollRestoration } from "react-router-dom";
import axios from "axios";
import Checkout_Payment from "../views/Checkout.Payment";
import Checkout_Cart from "../views/Checkout.Cart";
import Checkout_DisCount from "../views/Checkout.Discount";
import Paypal from "../components/PayPal";
import Cookies from "js-cookie";
import { FaCheck } from "react-icons/fa";
import { motion ,  AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';
import { renderCart, resetCart } from "../redux/slices/CartSlice";
import Price from "../components/Price";

const CheckOut = () => {
    const isMobile  = useMediaQuery({query: '(max-width: 1200px)'});
    const { t, i18n  } = useTranslation();
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartRedux = useSelector(state => state.cartSlice);
    const [isShowCartMobie, setIsShowCartMobie] = useState(false);

    const handleSetIsShowCartMobie = useCallback((value) => {
        setIsShowCartMobie(value);
    }, []);

    const [shipPrice, setShipPrice] = useState(0);

    const [nameValue, setNameValue] = useState('');
    const [mailValue, setMailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [addressValue, setAddressValue] = useState('');
    const [noteValue, setNoteValue] = useState('');
    const noteValueRef = useRef('');
    
    const [postalCodeValue, setPostalCodeValue] = useState('');

    const [countriesList, setCountriesList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);

    const [country, setCountry] = useState(currentLanguage === "vi" ? 'Vietnam' : '--');
    const [province, setProvince] = useState('--');
    const [provinceId, setProvinceId] = useState(0);
    const [district, setDistrict] = useState('--');

    const [inputValue, setInputValue] = useState("");
    const [countryForm, setCountryForm] = useState(false);
    const [provinceForm, setProvinceForm] = useState(false);
    const [districtForm, setDistrictForm] = useState(false);

    const [valueDiscount , setValueDiscount] = useState({codeName: '' , percent: 0});
    const [isSubcricbe, setIsSubcricbe] = useState(false);

    const [paymentValue , setPaymentValue] = useState({
        index: 'option_payment_1',
        value: currentLanguage === 'vi' ? 'Thanh toán khi nhận hàng (COD)' : 'Paypal'
    });
    const [checkouttPaypal , setCheckOutPaypal] = useState(false);

    const [isLoad, setIsLoad] = useState(false);
    useEffect(  () => {
        if(!isLoad){
            GetCountries().then((result) => {
                setCountriesList(result);
            });
            setIsLoad(true);

        }
    },[isLoad]);
    useEffect(()=>{
        if(currentLanguage === "vi"){
            GetState(240).then((result) => {
                setProvinceList(result);
            });
            setProvinceId(240);
        }
    },[currentLanguage]);


    useEffect(()=>{
        async function fetchData(){
            const cartCookieRaw = Cookies.get('_cookie-cart');
            const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/client/cart?language=${currentLanguage}&data=${cartCookieRaw}`);
            let price = 0;
            res.data.forEach(item => {
                price += item.number * item.price;
            });
            dispatch(renderCart({
                data: res.data,
                count: res.data.length,
                price: price
            }))
        }

        if(cartRedux.data.length === 0 || cartRedux.price === 0){
            fetchData();
        }
    }, [currentLanguage]);

    useEffect(()=>{
        if (
            (province === "Đồng Nai" && district === "Biên Hòa") ||
            (province === "Hồ Chí Minh" && district === "Ho Chi Minh City")
        ) {
            setShipPrice(25);
        } else{
            setShipPrice(35);

        }
    },[province , district]);


    const onChangeTextarea = (e)=>{
        noteValueRef.current.style.height = 'auto';
        noteValueRef.current.style.height = `${noteValueRef.current.scrollHeight}px`;
        heandleValue(e, setNoteValue, noteValue);
    }
    const heandleValue = (e, fc, id) => {
        fc(e.target.value)
    }

    const handleSubmit = async () => {
        const setMessage = (value) => {
            if (value.trim().length === 0 || value === '// Thiếu thông tin' || value === '// Thiếu thông tin' || value ==='--' ) {
                return currentLanguage === 'vi' ?  '// Thiếu thông tin' : '// Missing information';
            }
            return value;
        };
    
        setNameValue(setMessage(nameValue));
        setMailValue(setMessage(mailValue));
        setPhoneValue(setMessage(phoneValue));
        setAddressValue(setMessage(addressValue));
        setCountry(setMessage(country));
        if(currentLanguage === 'en')setPostalCodeValue(setMessage(postalCodeValue));

        if(nameValue.length !== 0 && 
            mailValue.length !== 0 && 
            phoneValue.length !== 0 && 
            addressValue.length !== 0 && 
            !nameValue.includes('//') && 
            !mailValue.includes('//') && 
            !phoneValue.includes('//') && 
            !addressValue.includes('//') && 
            country !== "--" &&
            paymentValue.length !== 0 &&
            (currentLanguage === 'vi' || (currentLanguage === 'en' && !postalCodeValue.includes('//')))            
        ){
            const form = {
                cart: cartRedux.data,
                info: {
                    fullName: nameValue,
                    email: mailValue,
                    phone: phoneValue,
                    address: addressValue,
                    country: country,
                    province: province,
                    district: district,
                    note: noteValue.length === 0 ? "none" : noteValue,
                    postalCode: postalCodeValue
                },
                location: currentLanguage,
                discount_code: valueDiscount,
                discount_price: cartRedux.discount,
                raw_price: cartRedux.price,
                payment: paymentValue.value,
                isSubcricbe: isSubcricbe,
                shipPrice: shipPrice
            }

            // if(paymentValue.value === 'Paypal'){
            //     setCheckOutPaypal(true);
            // }    
            // }else{
                try{
                    await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/client/payment`, form);
                    navigate('success');
                    dispatch(resetCart());
                    Cookies.set("_cookie-cart", []);
                }catch(err){

                }
            // }
        }
    };

    return (
        <div className={styles.layout}
            onClick={(e)=>{
                if(countryForm || provinceForm || districtForm){
                    if (!e.target.closest(`.${styles.inline_2} ul` )) {
                        setCountryForm(false);
                        setProvinceForm(false);
                        setDistrictForm(false);
                    }
                }
            }}
        >
            <ScrollRestoration
                    getKey={(location, matches) => {
                    return location.key;
                }}
            />
            <Outlet />
            <motion.div className={styles.payment}
                       initial={{ 
                            y: 60,
                            opacity: 0,
                       }}
                       animate={{ 
                            y: 0,
                            opacity: 1,
                        }}
                        transition={{
                            type: 'spring',
                            stiffness: 90,  
                            damping: 10
                        }}
            >
                <div className={styles.logo}>
                    <div>
                        <Logo  onClick={()=> navigate(-1)}/>
                        <p><Price price={cartRedux.price - cartRedux.discount - shipPrice} /></p>
                    </div>
                </div>
                <div className={styles.cartMobie}>
                    <button onClick={()=> handleSetIsShowCartMobie(true)}>{t('checkout.openCartMobitBtn')}</button>
                </div>
                <div>
                    <h2>{t('checkout.header_info')}</h2>
                </div>
                <div className={styles.infoFrame}>
                    <div className={`${styles.input} ${nameValue.includes('//') ? styles.noneValue : ''}`}>
                        <input id='payment_name' placeholder=" " onChange={(e) => heandleValue(e, setNameValue, nameValue)} type="text" value={nameValue} />
                        <label htmlFor='payment_name'>{t('checkout.fullName')}</label>
                    </div>
                    <div className={styles.inline_1}>
                        <div className={`${styles.input} ${mailValue.includes('//') ? styles.noneValue : ''}`}>
                            <input id='payment_email' placeholder=" " onChange={(e) => heandleValue(e, setMailValue, mailValue)} type="text" value={mailValue} />
                            <label htmlFor='payment_email'>{t('checkout.email')}</label>
                        </div>
                        <div className={`${styles.input} ${phoneValue.includes('//') ? styles.noneValue : ''}`}>
                            <input id='payment_phone' placeholder=" " onChange={(e) => heandleValue(e, setPhoneValue, phoneValue)} type="number" value={phoneValue} />
                            <label htmlFor='payment_phone'>{t('checkout.phone')}</label>
                        </div>
                    </div>
                    <div className={`${styles.input} ${addressValue.includes('//') ? styles.noneValue : ''}`}>
                        <input id='payment_address' placeholder=" " onChange={(e) => heandleValue(e, setAddressValue, addressValue)} type="text" value={addressValue} />
                        <label htmlFor='payment_address'>{t('checkout.address')}</label>
                    </div>
                    {
                        currentLanguage === "en" &&
                        <div className={`${styles.input} ${postalCodeValue.includes('//') ? styles.noneValue : ''}`}>
                            <input id='payment_postalCode' placeholder=" " onChange={(e) => heandleValue(e, setPostalCodeValue, addressValue)} type="text" value={postalCodeValue} />
                            <label htmlFor='payment_postalCode'>Postal Code</label>
                        </div>
                    }
                    <div className={`${styles.inline_2} ${styles.fullWidth}`}>
                        <div className={`${styles.input} ${country.includes('//') ? styles.noneValue : ''}`}
                            style={{
                                pointerEvents: currentLanguage === "vi" ? 'none' : 'all',
                                backgroundColor: currentLanguage === "vi" ?  '#F7F7F7' : 'auto'
                            }}
                        >
                                <div>
                                    <p
                                        onClick={() => {
                                            setCountryForm(!countryForm);
                                            setInputValue('')
                                        }}
                                    >
                                        <span>{country}</span>
                                        <FaAngleDown size={11} />
                                    </p>
                                    {countryForm && <ul>
                                        <div>
                                            <IoSearch />
                                            <input
                                                type="text"
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                placeholder="Enter country name"
                                            />
                                        </div>
                                        {inputValue.length > 0 ?
                                            countriesList
                                                .filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                                                .map((item, index) => (
                                                    <li
                                                        key={index}
                                                        value={index}
                                                        onClick={() => {
                                                            setCountry(item.name);
                                                            GetState(item.id).then((result) => {
                                                                setProvinceList(result);
                                                            });
                                                            setCountryForm(false);
                                                            setInputValue('');
                                                            setProvinceId(item.id);
                                                            setProvince('--');
                                                            setDistrict('--');
                                                            setDistrictList([]);
                                                        }}
                                                    >
                                                        {item.name}
                                                    </li>
                                                ))
                                            :
                                            countriesList.map((item, index) => (
                                                <li
                                                    key={index}
                                                    value={index}
                                                    onClick={() => {
                                        
                                                        setCountry(item.name);
                                                        GetState(item.id).then((result) => {
                                                            setProvinceList(result);
                                                        });
                                                        setProvinceId(item.id);
                                                        setCountryForm(false);
                                                        setProvince('--');
                                                        setDistrict('--');
                                                    }}
                                                >
                                                    {item.name}
                                                </li>
                                            ))
                                        }
                                    </ul>}
                                </div>
                                <label>{t('checkout.address_0')}</label>
                        </div>
                    </div>
                    <div className={styles.inline_2}>
                        <div className={styles.input}>
                            <div>
                                <p
                                    onClick={() => {
                                        setProvinceForm(!provinceForm);
                                        setInputValue('')
                                    }}
                                >
                                    <span>{province}</span>
                                    <FaAngleDown size={11} />
                                </p>
                                {provinceForm && <ul>
                                    <div>
                                        <IoSearch />
                                        <input
                                            type="text"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            placeholder="Enter state name"
                                        />
                                    </div>
                                    {inputValue.length > 0 ?
                                        provinceList
                                            .filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                                            .map((item, index) => (
                                                <li
                                                    key={index}
                                                    value={index}
                                                    onClick={() => {
                                                        setProvince(item.name);
                                                        GetCity(provinceId,item.id).then((result) => {
                                                            setDistrictList(result);
                                                        });
                                                        setProvinceForm(false);
                                                        setInputValue('');
                                                        setDistrict('--');
                                                    }}
                                                >
                                                    {item.name}
                                                </li>
                                            ))
                                        :
                                        provinceList.map((item, index) => (
                                            <li
                                                key={index}
                                                value={index}
                                                onClick={() => {
                                                    setProvince(item.name);
                                                    GetCity(provinceId, item.id).then((result) => {
                                                        setDistrictList(result);
                                                    });
                                                    setProvinceForm(false);
                                                    setDistrict('--');
                                                }}
                                            >
                                                {item.name}
                                            </li>
                                        ))
                                    }
                                </ul>}
                            </div>
                            <label>{t('checkout.address_1')}</label>
                        </div>
                        <div className={styles.input}>
                            <div>
                                <p
                                    onClick={() => {
                                        setDistrictForm(!districtForm);
                                        setInputValue('')
                                    }}
                                >
                                    <span>{district}</span>
                                    <FaAngleDown size={11} />
                                </p>
                                {districtForm && <ul>
                                    <div>
                                        <IoSearch />
                                        <input
                                            type="text"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            placeholder="Enter city name"
                                        />
                                    </div>
                                    {inputValue.length > 0 ?
                                        districtList
                                            .filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                                            .map((item, index) => (
                                                <li
                                                    key={index}
                                                    value={index}
                                                    onClick={() => {
                                                        setDistrict(item.name);
                                                        setDistrictForm(false);
                                                        setInputValue('')
                                                    }}
                                                >
                                                    {item.name}
                                                </li>
                                            ))
                                        :
                                        districtList.map((item, index) => (
                                            <li
                                                key={index}
                                                value={index}
                                                onClick={() => {
                                                    setDistrict(item.name);
                                                    setDistrictForm(false);
                                                }}
                                            >
                                                {item.name}
                                            </li>
                                        ))
                                    }
                                </ul>}
                            </div>
                            <label>{t('checkout.address_2')}</label>
                        </div>
                    </div>
                    <div className={`${styles.input} ${styles.styleNote}`}>
                        <textarea id="payment_note" placeholder=" " onChange={(e) => onChangeTextarea(e)} value={noteValue} ref={noteValueRef}></textarea>
                        <label htmlFor='payment_note'>{t('checkout.note')}</label>
                    </div>
                    <div className={styles.subscribeInput}>
                        <input type="checkbox" id='subscribeEmailCheckOut' checked={isSubcricbe}  onChange={e => setIsSubcricbe(e.target.checked)} />
                        <label htmlFor="subscribeEmailCheckOut">
                            <span className={styles.checkbox}>
                                <FaCheck fill="white" size={9}/>
                            </span>
                            {t('checkout.subcricbeEmail')}
                        </label>
                    </div>
                </div>
                <div className={styles.discount}>
                    <h2>{t('checkout.header_discount')}</h2>
                    <Checkout_DisCount text={t('checkout.check')} language={currentLanguage} setValueDiscount={setValueDiscount} />
                </div>
                <div className={styles.methodPayment}>
                    <h2>{t('checkout.header_methodPayment')}</h2>
                    <Checkout_Payment handle={setPaymentValue} value={paymentValue}/>
                </div>
                <div className={styles.order}>
                    <button onClick={handleSubmit}>{t('checkout.order')}</button>
                </div>
                <div className={styles.checkoutExtension}>
                    {checkouttPaypal ? <Paypal price={Number(cartRedux.price) - Number(cartRedux.discount)} /> : null}
                </div>
            </motion.div>
            <AnimatePresence>
                {
                    (isMobile && isShowCartMobie) || !isMobile ? (
                        <motion.div className={styles.cart}
                            key='Checkout_Cart'
                            initial={{
                                x:  isMobile? '-100%' : '100%'
                            }}
                            animate={{
                                x: 0
                            }}
                            exit={{
                                x:  isMobile? '-100%' : '100%'
                            }}
                            transition={{
                                type: 'spring',
                                stiffness: 60,  
                                damping: 15
                            }}
                        >
                            <div>
                                <div className={styles.cartCloseBtn}>
                                    <button onClick={() => handleSetIsShowCartMobie(false)}>
                                        <IoClose size={23} />
                                    </button>
                                </div>
                                <Checkout_Cart 
                                    text_1={t('checkout.cart_subtotal')} 
                                    text_2={t('checkout.cart_discount')} 
                                    text_3={t('checkout.cart_ship')} 
                                    text_4={t('checkout.cart_total')} 
                                    shipPrice={shipPrice}
                                    cartRedux={cartRedux} 
                                />
                            </div>
                        </motion.div>
                    ) : null
                }
            </AnimatePresence>
        </div>
    )
}

export default CheckOut;
