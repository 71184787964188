import React, { useState, memo } from "react";
import styles from "./CartItem.module.css";
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { editItemCart, deleteItemCart } from "../redux/slices/CartSlice";
import { motion, AnimatePresence } from "framer-motion";
import Price from "./Price";

const CartItem = ({ info, setIsOpen }) => {
    const dispatch = useDispatch();
    const [isDelete, setIsDelete] = useState(false);
    const [count, setCount] = useState(info.number);
    const PORT = process.env.REACT_APP_URL_BACKEND;

    const handleCookieCart = (newCount, mode) => {
        const cartCookieRaw = Cookies.get('_cookie-cart');
        const cartCookie = JSON.parse(cartCookieRaw || '[]');
        if (mode === 'edit') {
            const newData = cartCookie.map(item => {
                if (item.url === info.url && item.size === info.size) {
                    return { ...item, number: newCount };
                } else {
                    return item;
                }
            });
            Cookies.set('_cookie-cart', JSON.stringify(newData));
        }
        if (mode === 'delete') {
            const newData = cartCookie.filter(item => !(item.url === info.url && item.size === info.size));
            Cookies.set('_cookie-cart', JSON.stringify(newData));
        }
    };

    const handleClick = (mode) => {
        let newCount = count;
        if (mode === 'add' && count < 100) {
            newCount = count + 1;
            setCount(newCount);
            handleCookieCart(newCount, 'edit');
            dispatch(editItemCart({
                mode: 'add',
                url: info.url,
                size: info.size,
                number: newCount,
            }));
        }
        if (mode === 'subtract' && count > 1) {
            newCount = count - 1;
            setCount(newCount);
            handleCookieCart(newCount, 'edit');
            dispatch(editItemCart({
                mode: 'subtract',
                url: info.url,
                size: info.size,
                number: newCount,
            }));
        }
        if (mode === 'subtract' && count === 1) {
            setIsDelete(true);
            handleCookieCart(newCount, 'delete');
           
        }
    };

    const handleRemove = () => {
        dispatch(deleteItemCart({
            url: info.url,
            size: info.size,
            price: info.price
        }));
    };

    return (
        <AnimatePresence onExitComplete={handleRemove}>
            {
                !isDelete &&
                <motion.div
                    className={styles.layout}
                    key={`cartItem_${info.url}_${info.size}`}
                    initial={{ opacity: 0 }}
                    animate={{ 
                        opacity: 1,  
                    }}
                    exit={{ 
                        height: 0, 
                        marginBottom: 0, 
                        opacity: 0
                    }}
                    transition={{ 
                        duration: 0.45 ,
                        ease: 'easeOut'

                     }}
                >
                    <div className={styles.imgFrame}>
                        <img 
                            alt={`${info.url} Cart | Terror Clothes  `}
                            src={`${PORT}/images/${info.url}/main.jpg`}
                        />
                    </div>
                    <div className={styles.contentFrame}>
                        <div>
                            <Link onClick={() => setIsOpen(false)} to={`/collections/${info.type}/${info.url}`}>{info.name}</Link> 
                        </div>
                        <div>
                            <p>Size: {info.size}</p>
                            <div className={styles.price}>
                                <p>
                                    <Price price={info.price} />
                                </p>
                                {info.saleRawPrice !== 0 &&  <p><Price price={info.saleRawPrice} /></p>}
                            </div>
                            <div>
                                <button onClick={() => handleClick('subtract')}>-</button>
                                <p>{count}</p>
                                <button onClick={() => handleClick('add')}>+</button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    );
};

export default memo(CartItem);
