import React from 'react';
import styles  from './Slider.module.css';

const Slider =({url , imgExtraLength}) => {
    const PORT = process.env.REACT_APP_URL_BACKEND;


    return (
            <div className={styles.container}>
                <div
                    className={styles.slides}
                >
                    <div>
                        <img
                            src={`${PORT}/images/${url}/main.jpg`}
                            alt={`${url} Images 1 | Terror Clothes`}
                        />
                    </div>    
                    <div>
                        <img
                            src={`${PORT}/images/${url}/hover.jpg`}
                            alt={`${url} Images 2 | Terror Clothes`}
                        />
                    </div>
                    {[...Array(imgExtraLength).keys()].map((_, i) => (
                        <div key={`${i}-item-image`}>
                            <img
                                key={`imgExtra-${i}`}
                                src={`${PORT}/images/${url}/extra-${i + 1}.jpg`}
                                alt={`${url} Images ${i + 3} | Terror Clothes`}
                            />
                        </div>
                    ))}
                </div>
            </div>
    );
}


export default Slider;