import React , { useState, useEffect , memo} from "react";
import styles from './CookieAccept.module.css';
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import Cookies from "js-cookie";
import {  useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { motion , AnimatePresence } from "framer-motion";
import { openNewsletters } from "../redux/slices/NewsletterSlice";



const CookieAccept = ({isOpen , setIsOpen}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isShowModel, setIsShowModel] = useState(false);
  
    const [isCustomise , setIsCustomise] = useState(false);

    useEffect(()=>{
        if(isOpen){
            setIsShowModel(true);
            setIsOpen(false);
        }
    }, [isOpen]);
  

    const [isFunctionCookie , setIsFunctionCookie] = useState (true);
    const [isPerformanceCookie , setIsPerformanceCookie] = useState (true);
    const [isAdvertisingCookie , setIsAdvertisingCookie] = useState (true);

    const Swicht = ({isOn, btn})=>{
       return (
        <div className={styles.swicht}>
            <div onClick={()=> btn(!isOn)}  className={`${styles.outter} ${isOn ? styles.modeOn : styles.modeOff }`}>
                <div className={styles.inner}></div>
            </div>
        </div>
       )
    }

    const handleClickCustomiseCookie = ()=>{
        let arr = [];
        if(isFunctionCookie){
            arr.push('_functionCookie');
        }
        if(isPerformanceCookie){
            arr.push('_performanceCookie');
        }
        if(isAdvertisingCookie){
            arr.push('_advertisingCookie');
        }
        if(isFunctionCookie && isPerformanceCookie && isAdvertisingCookie){
            Cookies.set('_cookie-acceptCookies', '_all' , { expires: 180 });
        }else{
            Cookies.set('_cookie-acceptCookies', JSON.stringify(arr), { expires: 180 });
        }
        setIsShowModel(false);
        const timer = setTimeout(() => {
            dispatch(openNewsletters());
            clearTimeout(timer); 
        }, 1000);
    }
    const handleClickAllCookie = ()=>{
        setIsShowModel(false);
        Cookies.set('_cookie-acceptCookies' , '_all' , { expires: 180 });
        const timer = setTimeout(() => {
            dispatch(openNewsletters());
            clearTimeout(timer); 
        }, 1000);
    }

    return (
        isShowModel && <AnimatePresence> 
                     <div 
                        className={styles.layout}
                    >
                        {
                            !isCustomise?
                            <>
                                <motion.div className={styles.frame_1}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        duration: 0.4
                                    }}
                                >
                                    <div className={styles.contentFrame}>
                                        <h2>{t('cookies.header')}</h2>
                                        <div>
                                            <p>{t('cookies.p_1')} <Link to={'/policy/about-cookie'}>{t('cookies.link')}</Link></p>
                                        </div>
                                    </div>
                                    <div className={styles.btnFrame}>
                                        <button onClick={handleClickAllCookie}>{t('cookies.btn_1')}</button>
                                        <button onClick={() => setIsCustomise(true)}>{t('cookies.btn_2')}</button>
                                    </div>
                                </motion.div>  
                            </>
                            :
                            <>
                                <div className={styles.frame_2}>
                                    <div>
                                        <div className={styles.goBack}>
                                            <button onClick={() => setIsCustomise(false)}><FaArrowLeftLong /></button>
                                        </div>
                                        <div className={styles.contentFrame}>
                                            <div>
                                                <p>{t('cookies.customise_p_1')}</p>
                                                <br />
                                                <p>{t('cookies.customise_p_2')}<Link to={'/policy/about-cookie'}>{t('cookies.link')}</Link></p>
                                            </div>
                                            <div>
                                                <div>
                                                    <h2>{t('cookies.customise_h_1')}</h2>
                                                    <div className={styles.swicht}>{t('cookies.customise_active')}</div>
                                                </div>
                                                <div>
                                                    <p>{t('cookies.customise_h1_p')}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h2>{t('cookies.customise_h_2')}</h2>
                                                    <Swicht isOn={isFunctionCookie} btn={setIsFunctionCookie} />
                                                </div>
                                                <div>
                                                    <p>{t('cookies.customise_h2_p')}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h2>{t('cookies.customise_h_3')}</h2>
                                                    <Swicht isOn={isPerformanceCookie} btn={setIsPerformanceCookie}/>
                                                </div>
                                                <div>
                                                    <p>{t('cookies.customise_h3_p')}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h2>{t('cookies.customise_h_4')}</h2>
                                                    <Swicht isOn={isAdvertisingCookie} btn={setIsAdvertisingCookie}/>
                                                </div>
                                                <div>
                                                    <p>{t('cookies.customise_h4_p')}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <button onClick={handleClickCustomiseCookie}>{t('cookies.btn_3')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>   
                        }
                     </div>
        </AnimatePresence>
    );
};

export default memo(CookieAccept)