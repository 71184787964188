import React , {memo} from "react";
import styles from './Header.module.css';
import  { ReactComponent as Logo } from '../assets/logo.svg';
import { NavLink , Link   } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { RxTriangleDown } from "react-icons/rx";
import { PiCellSignalHighBold } from "react-icons/pi";

const Header = () =>{
    const { t } = useTranslation();
    
    return(
        <div className={styles.layout}>
            <div className={styles.logoFrame}>
                <Link to={'/'}>
                    <Logo />
                </Link>
            </div>
            <div className={styles.menuFrame}>
                <div>
                    <div className={styles.navbar}>
                        <div className={styles.link}>
                                <Link to={'/collections/'}>All</Link>
                                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'/collections/tops'}>Tops</NavLink>
                                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'/collections/bottoms'}>Bottoms</NavLink>
                                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'/collections/outerwears'}>Outerwears</NavLink>
                                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'/collections/accessories'}>Accessories</NavLink>
                        </div>
                    </div>
                </div>
                <div className={styles.option}>
                    <div>
                        <button>
                            {t('collections.productHeader.filter')}
                            <RxTriangleDown size={15} />
                        </button>
                    </div>
                    <div>
                        <button><PiCellSignalHighBold size={23}/></button>
                        <button><PiCellSignalHighBold size={23}/></button>
                        <button>New</button>
                        <button>Best</button>
                        <button>Sell</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Header)