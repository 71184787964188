import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    price: 0,
    count: 0,
    discount: 0
};

const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        renderCart: (state , action) => {
            state.price = action.payload.price;
            state.count = action.payload.count;
            state.data = action.payload.data;
        },
        addItemCart: (state , action) => {
            state.data.push(action.payload.data);
            state.price += action.payload.data.price;
            state.count += 1;
        },
        editItemCart: (state, action) =>{
            state.data = state.data.map(item =>{
                if(item.url === action.payload.url && item.size === action.payload.size){
                    if(action.payload.mode === 'add'){
                        state.price +=  item.price;
                    }
                    if(action.payload.mode === 'subtract'){
                        state.price -=  item.price;;
                    }
                    return {
                        ...item,
                        number: action.payload.number
                    }
                }else{
                    return item
                }
            })
        },
        deleteItemCart: (state, action) =>{
            state.data = state.data.filter(item=> { return !(item.url === action.payload.url && item.size === action.payload.size)});
            state.price -= action.payload.price;
            state.count -= 1;
        },
        discountCart: (state, action) =>{
            const percent = action.payload.percent;
            state.discount  = Math.round(state.price * (percent / 100)); ;
        },
        resetCart:  (state) =>{
            state.data = [];
            state.price = 0;
            state.count = 0;
            state.discount = 0;
        },
    }
});

export const {  renderCart, addItemCart , editItemCart , deleteItemCart ,discountCart, resetCart } = cartSlice.actions;
export default cartSlice.reducer;
