import React, {memo} from "react";
import styles from './Checkout.Cart.module.css';
import Price from "../components/Price";

const Checkout_Cart = ({ text_1, text_2, text_3 , text_4 , shipPrice , cartRedux})=>{

    const Item = ({value})=>{
        return (
            <div className={styles.item}>
                <div>
                    <div className={styles.item_img}>
                        <img 
                            src={`${process.env.REACT_APP_URL_BACKEND}/images/${value.url}/main.jpg`} 
                            alt="Checkout Cart Images | Terror Clothes"
                        />
                        <p>{value.number}</p>
                    </div>
                    <div className={styles.item_content}>
                        <p>{value.name} </p>
                        <p>Size: {value.size}</p>
                    </div>
                </div>
                <div className={styles.item_price}>
                    <p><Price price={value.price} /></p>
                </div>
            </div>
        )
    }


    return(
        <div className={styles.layout}>
            <div>
                {cartRedux.data.map((item, index)=>{
                    return <Item key={`${item.url}_${index}`} value={item} />
                })}
            </div>
            <div className={styles.total}>
                <div>
                    <p>{text_1}</p>
                    <p><Price price={cartRedux.price}/></p>
                </div>
                <div>
                    <p>{text_2}</p>
                    <p><Price price={cartRedux.discount}/></p>
                </div>
                <div>
                    <p>{text_3}</p>
                    <p><Price price={shipPrice}/></p>
                </div>
            </div>
            <div className={styles.total}>
                <div>
                    <p>{text_4}</p>
                    <p><Price price={cartRedux.price - cartRedux.discount - shipPrice}/></p>
                </div>
            </div>
                

        </div>
    )
}
export default memo(Checkout_Cart)