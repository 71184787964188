import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoadingStart: true,
};

const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        renderLoadingStart: (state , action) => {
            state.isLoadingStart = action.payload.mode;
        }
        
    }
});

export const { renderLoadingStart  } = appSlice.actions;
export default appSlice.reducer;
