import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';
const languageFromCookie = Cookies.get('_cookie-language') || 'vi';


i18n
//   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'vi',
    lng: languageFromCookie,
    interpolation: {
      escapeValue: false, 
    },
    resources: {
        en: {
          translation: {
            location: 'International',
            collections: {
              productHeader:{
                filter: 'Sort',
              }
            },
            newsletter:{
              info: 'Unlock exclusive benefits: new products, snag discount codes, enjoy special promotions, and much more.',
              susscessHeader: 'Newsletter registration successful',
              susscessContent: 'We will send a confirmation email to the address you have registered. In case you do not receive an email from us, please send feedback to our email address at:',
            },
            cookies: {
              header: 'Before You Start Shopping',
              p_1: 'We use cookies to enhance website performance, improve user experience, and support our marketing efforts. ',
              link: 'Cookie Policy.',
              btn_1: 'Accept All',
              btn_2: 'Customise',
              btn_3: 'Confirm my preferences',
              customise_p_1: 'Because we respect your privacy, you can choose not to allow certain types of cookies. However, please note that blocking some types of cookies may impact how we can provide and improve our services for you. When you\'re ready, click \'Confirm my choices\' and remember, you can always change your decision later.',
              customise_p_2: 'If you want to learn more about cookies and why we use them, visit our Cookie Policy page anytime.',
              customise_h_1: 'Essential Cookies',
              customise_h_2: 'Functional Cookies',
              customise_h_3: 'Analytical Cookies',
              customise_h_4: 'Advertising Cookies',
              customise_active: 'Always Active',
              customise_h1_p: 'Essential cookies are cookies that cannot be turned off and are used to enable basic website features such as creating a shopping cart and accessing certain areas of the website. These cookies play a crucial role in maintaining our services and do not store any personal data.',
              customise_h2_p: 'Functional cookies provide advanced features to enhance user experience, including remembering your shopping cart, account, language preferences, and region.',
              customise_h3_p: 'These cookies are used to collect information about how users interact with our website. By recording visits, traffic, and activities on the website, we can better understand how visitors navigate the website and improve user experience. Data collected includes most visited pages, time spent on pages, and specific actions such as clicks and scrolling, ... Data collected through analytical cookies is encrypted and anonymized to protect user privacy.',
              customise_h4_p :'Advertising cookies are used to deliver relevant advertising content based on your interests. They may be set by our advertising partners to build your profile and display relevant ads on our website and third-party websites. By accepting advertising cookies, users consent to advertising partners collecting, processing, and using their personal information to create personalized advertising experiences. This helps improve the effectiveness of advertising campaigns and provide users with more engaging and relevant advertising content.'
            },
            checkout:{
              openCartMobitBtn: 'Display order information',
              header_info: 'Customer Infomation',
              header_methodPayment: 'Payment Method',
              header_discount: 'Discount Code',
              fullName: 'Full Name',
              email: 'Email',
              phone: 'Phone',
              address: 'Address',
              address_0: 'Country',
              address_1: 'Province',
              address_2: 'District',
              address_3: 'Ward',
              note: 'Note',
              order: 'Pay',
              subcricbeEmail: "Subscribe to our newsletter with your current Email.",
              check: 'Check',
              cart_subtotal: 'Subtotal',
              cart_discount: 'Discount',
              cart_ship: 'Ship',
              cart_total: 'Total'
            }
          }
        },
        vi: {
          translation: {
            location: 'Việt Nam',
            collections: {
              productHeader:{
                filter: 'Lọc',
              }
            },
            newsletter:{
              info: 'Mở khóa những lợi ích độc quyền: sản phẩm mới, mã giảm giá, chương trình khuyến mãi đặc biệt,...',
              susscessHeader: 'Đăng kí bản tin thành công',
              susscessContent: 'Chúng tôi sẽ gửi một email xác nhận đến địa chỉ bạn đã đăng kí. Trong trường hợp bạn không nhận được email từ chúng tôi, hãy gửi phản hồi qua địa chỉ email của chúng tôi tại:',
            },
            cookies: {
              header: 'Trước khi bắt đầu mua sắm',
              p_1: 'Chúng tôi sử dụng cookie để nâng cao hiệu suất trang web, cải thiện trải nghiệm người dùng và hỗ trợ các nỗ lực tiếp thị của chúng tôi. ',
              link: 'Chính sách Cookie của chúng tôi.',
              btn_1: 'Chấp nhận',
              btn_2: 'Tùy chỉnh',
              btn_3: 'Xác nhận lựa chọn của tôi',
              customise_p_1: 'Vì chúng tôi tôn trọng quyền riêng tư của bạn nên bạn có thể chọn không cho phép một số loại cookie. Tuy nhiên, lưu ý rằng việc chặn một số loại cookie có thể ảnh hưởng đến cách chúng tôi có thể cung cấp và cải thiện dịch vụ của mình cho bạn. Khi bạn đã sẵn sàng, hãy nhấp vào \'Xác nhận lựa chọn của tôi\' và nhớ rằng, bạn luôn có thể thay đổi quyết định sau này.',
              customise_p_2: 'Nếu bạn muốn tìm hiểu thêm về cookie và lý do chúng tôi sử dụng chúng, hãy truy cập trang Chính sách Cookie của chúng tôi bất cứ lúc nào.',
              customise_h_1: 'Cookie cần thiết',
              customise_h_2: 'Cookie chức năng',
              customise_h_3: 'Cookie phân tích',
              customise_h_4: 'Cookie quảng cáo',
              customise_active: 'Luôn hoạt động',
              customise_h1_p: 'Các cookie cần thiết là những cookie không thể tắt và được sử dụng để kích hoạt các tính năng cơ bản của trang web như tạo giỏ hàng và truy cập vào một số khu vực của trang web. Những cookie này đóng vai trò quan trọng trong việc duy trì dịch vụ của chúng tôi và không lưu trữ bất kỳ dữ liệu cá nhân nào.',
              customise_h2_p: 'Cookie chức năng cung cấp các tính năng nâng cao để cải thiện trải nghiệm người dùng, bao gồm việc ghi nhớ giỏ hàng, tài khoản, lựa chọn ngôn ngữ và khu vực của bạn.',
              customise_h3_p: 'Các cookie này được sử dụng để thu thập thông tin về cách người dùng tương tác với trang web của chúng tôi. Thông qua việc ghi lại số lượt truy cập, lưu lượng truy cập và các hoạt động trên trang web, chúng tôi có thể hiểu rõ hơn về cách khách truy cập di chuyển trên trang web và cải thiện trải nghiệm người dùng. Dữ liệu thu thập được bao gồm trang nào được truy cập nhiều nhất, thời gian dành trên trang, và các hành động cụ thể như nhấp chuột và cuộn trang,... Dữ liệu thu thập thông qua cookie phân tích được mã hóa và ẩn danh để bảo vệ quyền riêng tư của người dùng.',
              customise_h4_p :'Cookie quảng cáo được sử dụng để cung cấp nội dung quảng cáo phù hợp với sở thích của bạn. Chúng có thể được thiết lập bởi các đối tác quảng cáo của chúng tôi để xây dựng hồ sơ của bạn và hiển thị quảng cáo có liên quan trên trang web của chúng tôi và các trang web của bên thứ ba. Thông qua việc chấp nhận cookie quảng cáo, người dùng đồng ý cho các đối tác quảng cáo thu thập, xử lý và sử dụng thông tin cá nhân của họ để tạo ra trải nghiệm quảng cáo cá nhân hóa. Điều này giúp cải thiện hiệu quả của các chiến dịch quảng cáo và cung cấp cho người dùng nội dung quảng cáo hấp dẫn và phù hợp hơn.'
            },
            checkout:{
              openCartMobitBtn: 'Hiển thị thông tin đơn hàng',
              header_info: 'Thông tin khách hàng',
              header_methodPayment: 'Phương thức thanh toán',
              header_discount: 'Mã giảm giá',
              fullName: 'Họ và tên',
              email: 'Email',
              phone: 'Điện thoại',
              address: 'Địa chỉ',
              address_0: 'Quốc gia',
              address_1: 'Tỉnh',
              address_2: 'Quận/Huyện',
              address_3: 'Phường/ xã',
              note: 'Ghi chú',
              order: 'Đặt hàng',
              subcricbeEmail: "Đăng kí bản tin với mail hiện tại.",
              check: 'Sử dụng',
              cart_subtotal: 'Tạm tính',
              cart_discount: 'Giảm giá',
              cart_ship: 'Phí vận chuyển',
              cart_total: 'Tổng'
            }
          }
      },
    }
  });

export default i18n;