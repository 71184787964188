import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'

import appSlice from './slices/AppSlice';
import cartSlice from './slices/CartSlice';
import newsletterSlice from './slices/NewsletterSlice';

const rootReducer = combineReducers({
  appSlice,
  cartSlice,
  newsletterSlice,
});


const store = configureStore({
  reducer: rootReducer,
});


export default store;
