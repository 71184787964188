import React, { useState, useEffect} from "react";
import { Link, useLoaderData, useNavigate ,useParams, ScrollRestoration } from "react-router-dom";
import styles from './ProductDetail.module.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from "js-cookie";
import { useDispatch } from 'react-redux';
import { FaAngleRight } from "react-icons/fa";
import Slider from "./Slider";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as Logo } from '../assets/logo.svg';
import { addItemCart, editItemCart } from "../redux/slices/CartSlice";
import SizeGuide from './SizeGuide';
import { LuAlignLeft } from "react-icons/lu";
import Price from "./Price";


export const fetchItem = async ({ params }) => {
    const language = Cookies.get("_cookie-language") || 'vi';
    const { id } = params;
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/client/item?language=${language}&&url=${id}`);
    return res.data;
}

const easing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            y: {duration: 0.3},
            opacity: {duration: 0.2},
            ease: easing,
        }
    }
};




const ProductDetail = () => {
    const PORT = process.env.REACT_APP_URL_BACKEND;
    let { type } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const value = useLoaderData();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [sizeOption, setSizeOption] = useState('S');
    const [isOpenSizeGuide, setIsOpenSizeGuide] = useState(false);
    const [isShowNavbar, setIsShowNavbar] = useState(false);

    useEffect(() => {
        const sizes = ['S', 'M', 'L'];
        for (const size of sizes) {
            if (Number(value.sizeOption[`size_${size}`]) !== 0) {
                setSizeOption(size);
                break;
            }
        }
    }, [value.sizeOption]);

    const handleShowNavbar = () => {
        setIsShowNavbar(true);
    };
    const handleMouseLeave = ()=>{
        setIsShowNavbar(false);
    };

    const handleAddToCart = async () => {
        const getCookieData = () => {
            const cookieData = Cookies.get('_cookie-cart');
            if (cookieData) {
                return JSON.parse(cookieData);
            } else {
                return [];
            }
        };

        let newData = await getCookieData();
        let existingItemIndex = await newData.findIndex(item => item.url === value.url && item.size === sizeOption);

        if (existingItemIndex !== -1) {
            newData = newData.map(item => {
                if (item.url === value.url && item.size === sizeOption) {
                    dispatch(editItemCart({
                        mode: 'add',
                        url: item.url,
                        size: item.size,
                        number: item.number + 1,
                    }));
                    return {
                        ...item,
                        number: item.number + 1
                    }
                } else {
                    return item
                }
            });
        } else {
            let data = {
                url: value.url,
                size: sizeOption,
                number: 1
            };
            newData.push(data);
            const res = await axios.get(`${PORT}/api/client/cart/add-item?language=${currentLanguage}&&data=${JSON.stringify(data)}`);
            dispatch(addItemCart({ data: res.data }));
        }

        Cookies.set("_cookie-cart", JSON.stringify(newData));
    };

    return (
        <div className={styles.layout}>
            <ScrollRestoration
                getKey={(location, matches) => {
                    return location.key;
                }}
            />
            <SizeGuide isOpen={isOpenSizeGuide} setIsOpen={setIsOpenSizeGuide} url={value.url} />
            <div className={styles.header}>
                <button className={styles.navbarBtn}><LuAlignLeft size={22} onClick={handleShowNavbar} /></button>
                <AnimatePresence>
                    {isShowNavbar && (
                        <motion.div className={styles.navbar}
                            onMouseLeave={handleMouseLeave}
                            initial={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                            animate={{ y: 35, pointerEvents: 'all', opacity: 1 }}
                            exit={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                            transition={{ type: "spring", stiffness: 200, damping: 20 }}
                        >
                            <motion.div
                                key='Menu_All'
                                initial={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                animate={{ y: 23, pointerEvents: 'all', opacity: 1 }}
                                exit={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                transition={{ type: "spring", stiffness: 200, damping: 20 }}
                            >
                                <Link to={'/collections/tops'}>All</Link>
                            </motion.div>
                            <motion.div
                                key='Menu_Tops'
                                initial={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                animate={{ y: 43, pointerEvents: 'all', opacity: 1 }}
                                exit={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                transition={{ type: "spring", stiffness: 200, damping: 20 }}
                            >
                                <Link to={'/collections/tops'}>Tops</Link>
                            </motion.div>
                            <motion.div
                                key='Menu_Bottoms'
                                initial={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                animate={{ y: 63, pointerEvents: 'all', opacity: 1 }}
                                exit={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                transition={{ type: "spring", stiffness: 200, damping: 20 }}
                            >
                                <Link to={'/collections/bottoms'}>Bottoms</Link>
                            </motion.div>
                            <motion.div
                                key='Menu_Outerwears'
                                initial={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                animate={{ y: 83, pointerEvents: 'all', opacity: 1 }}
                                exit={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                transition={{ type: "spring", stiffness: 200, damping: 20 }}
                            >
                                <Link to={'/collections/outerwears'}>Outerwears</Link>
                            </motion.div>
                            <motion.div
                                key='Menu_Accessories'
                                initial={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                animate={{ y: 103, pointerEvents: 'all', opacity: 1 }}
                                exit={{ y: 0, pointerEvents: 'none', opacity: 0 }}
                                transition={{ type: "spring", stiffness: 200, damping: 20 }}
                            >
                                <Link to={'/collections/accessories'}>Accessories</Link>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
                <button className={styles.logo} onClick={() => navigate(`/collections/`)}><Logo /></button>
            </div>
            <div className={styles.main}>
                <div className={styles.imgFrame}>
                    <div className={styles.imgShow}>
                        <Slider url={value.url} imgExtraLength={value.imgExtraLength} />
                    </div>
                </div>
                <motion.div className={styles.contentFrame} variants={fadeInUp} initial="initial" animate="animate">
                    <div>
                        <div className={styles.headerProduct}>
                            <h1>{value.name}</h1>
                            <div>
                                <p>
                                    <Price  price={value[`price_${currentLanguage}`]} language={currentLanguage} />
                                </p>
                                {value.status === "Promotion" && 
                                    <p>
                                        <Price  price={value[`salePrice_${currentLanguage}`]}  />
                                    </p>
                                }
                            </div>
                        </div>
                        <div className={styles.description}>
                            {value[`description_list_${currentLanguage}`] && value[`description_list_${currentLanguage}`].map((item, index) => (
                                <p key={`description_${index}`}>- {item}</p>
                            ))}
                        </div>
                        <div className={styles.optionSize}>
                            {Number(value.sizeOption.size_S) !== 0 ? <button className={sizeOption === "S" ? styles.sizeOption_Active : styles.sizeOption_NoneActive} onClick={() => setSizeOption('S')}>S</button> : null}
                            {Number(value.sizeOption.size_M) !== 0 ? <button className={sizeOption === "M" ? styles.sizeOption_Active : styles.sizeOption_NoneActive} onClick={() => setSizeOption('M')}>M</button> : null}
                            {Number(value.sizeOption.size_L) !== 0 ? <button className={sizeOption === "L" ? styles.sizeOption_Active : styles.sizeOption_NoneActive} onClick={() => setSizeOption('L')}>L</button> : null}
                        </div>
                        <div  className={styles.similar}
                            style={{ borderBottom: value.related.length === 0 ? 'none' : undefined }}
                        >
                            {value.related.length !== 0 ? <label>{value.color}</label> : null}
                            {value.related.map((item, index) => (
                                <div key={`similar-item-${index}`}>
                                    <Link to={`/collections/${type}/${item.url}`}>
                                        <label>{item.name}</label>
                                        <img 
                                            src={`${PORT}/images/${item.url}/main.jpg`} 
                                            alt={`${item.url} Related Image| Terror Clothes`}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div>
                                <button  className={styles.addToCart} onClick={handleAddToCart}>Add to cart</button>
                                <button  className={styles.sizeGuide} onClick={() => setIsOpenSizeGuide(true)}>Size Guide <FaAngleRight size={11} /></button>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default ProductDetail;
