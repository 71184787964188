import React from "react";
import styles from './Price.module.css';
import i18n from 'i18next';

const Price = ({price }) => {
    const currentLanguage = i18n.language;

    const formattedPrice = currentLanguage  === 'vi'  ? (Number(price) * 1000).toLocaleString('en-US') : price;

    return (
        <span className={styles.layout}>
            {formattedPrice}
            {currentLanguage === 'vi' && (<><span>đ</span></>)}
        </span>
    );
}

export default Price;