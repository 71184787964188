import React , {useState, useEffect } from "react";
import styles from './NewSletter.module.css'
import { motion, AnimatePresence } from "framer-motion";
import { FaArrowRightLong } from "react-icons/fa6";
import { VscChromeClose } from "react-icons/vsc";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { finishNewsletters } from "../redux/slices/NewsletterSlice";


const NewSletter = ()=> {
    const [valueText, setValueText] = useState('');
    const [isSubmitSuccess , setIsSubmitSuccess] = useState(false);
    const [isShowModel, setIsShowModel] = useState(false);
    const { t, i18n  } = useTranslation();
    const currentLanguage = i18n.language;
    const isOpenMoDelRedux = useSelector(state => state.newsletterSlice.isMode);
    const dispatch = useDispatch();

    const location = useLocation();
    const handleOnChange = (e) =>{
        setValueText(e.target.value);
    }
    const PORT = process.env.REACT_APP_URL_BACKEND;

    useEffect(()=>{
        if(location.pathname.includes('/collections')){
            const newsletter = sessionStorage.getItem('_session-modelNewsletter');
            const cookie = Cookies.get('_cookie-acceptCookies');
    
            if(!newsletter && cookie){
                setIsShowModel(true);
            }
            if (isOpenMoDelRedux) {
                setIsShowModel(true);
                dispatch(finishNewsletters())
            }
        }
    }, [location.pathname, isOpenMoDelRedux ]);



    const onSubmit = async () =>{
        if(valueText.includes('//') || valueText.trim().length === 0) return
        const request = await axios.post(`${PORT}/api/client/newsletter/create`, {email: valueText})
        if(request.data === "fail"){
            
            setValueText(currentLanguage === 'vi'? '// Email này đã được đăng kí' : '// Email already exists')
        }
        if(request.data === "success"){
            setIsSubmitSuccess(true);
        }
    }
   
    const handleClose = ()=>{
        sessionStorage.setItem('_session-modelNewsletter', true);
        setIsShowModel(false);
        setIsSubmitSuccess(false);
    }

    return(
            <AnimatePresence>
                {isShowModel &&    
                    <motion.div className={styles.layout}
                        key='NewSletter'
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{  duration: 0.4, ease: "easeOut" }}
                    >
                        <div>
                            <button className={styles.closeBtn} onClick={handleClose}><VscChromeClose size={22} /></button>
                            {
                                isSubmitSuccess ? 
                                <div className={styles.successBox}>
                                    <h2>{t('newsletter.susscessHeader')}</h2>
                                    <p>{t('newsletter.susscessContent')} <span>info@terror-clo.com</span></p>
                                </div>
                                :
                                <div>
                                    <div className={styles.top}>
                                        <h2>NEWSLETTER</h2>
                                    </div>
                                    <div className={styles.center}>
                                        <input  type='text' value={valueText} onChange={e => handleOnChange(e)} 
                                            style={{
                                                color: valueText.includes('//') && '#af3030'
                                            }}
                                        /> 
                                            <button onClick={onSubmit}><FaArrowRightLong size={20} /></button>
                                    </div>
                                    <div className={styles.bottom}>
                                        <p>{t('newsletter.info')}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>   
                 
    )
}

export default NewSletter;