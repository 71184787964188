import { useRouteError } from "react-router-dom";
import styles from './ErrorPage.module.css';
import { Link } from "react-router-dom";


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className={styles.layout}>
      <div>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        {/* <p>Error: <i>{error.statusText || error.message}</i></p> */}
        <div>
          <Link to={'/'}>Go back</Link>
        </div>
      </div>

    </div>
  );
}