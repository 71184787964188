import React, { useState } from "react";
import styles from "./ProductItem.module.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import i18n from 'i18next';
import { useMediaQuery } from 'react-responsive';
import Price from "./Price";

const ProductItem = ({ value }) => {
    const [isHovered, setIsHovered] = useState(false);
    const PORT = process.env.REACT_APP_URL_BACKEND;
    const currentLanguage = i18n.language;
    const isMobile  = useMediaQuery({query: '(max-width: 768px)'});
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderPrice = () => {
        if (value.status !== "No") {
            if (value.status === "Promotion") {
                return `-${value.salePrice_percent}%`;
            } else {
                return `${value.status}`;
            }
        }
    };

  

    return (
        <div className={styles.layout}>
            <div className={styles.imgFrame}>
                <Link to={`/collections/${value.type}/${value.url}`}>                
                        <LazyLoadImage
                            alt={value.url}
                            src={`${PORT}/images/${value.url}/main.jpg`}
                            effect="blur" 
                            height={200}
                            width={100}
                        />
                    {
                        !isMobile &&
                        <LazyLoadImage
                            alt={value.url}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className={isHovered ? styles.activeImg : ''}
                            src={`${PORT}/images/${value.url}/hover.jpg`}
                            effect="blur" 
                            height={200}
                            width={100}
                        />
                    }
                </Link>
                {value.status !== 'No' &&   <p className={styles.status}>{renderPrice()}</p> }
            </div>
            <div className={styles.label}>
                <h2    
                    className={styles.name}
                >
                    {value.name}
                </h2>
                <div>
                    {value.status === 'Promotion' ? <p>{value[`salePrice_${currentLanguage}`]}</p> : null}
                    <p>
                        <Price price={value[`price_${currentLanguage}`]} />
                    </p>
                   
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
