import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMode: false,
};

const newsletterSlice = createSlice({
    name: 'cookieSlice',
    initialState,
    reducers: {
        openNewsletters: (state) => {
            state.isMode = true; 
        },
        finishNewsletters: (state) => {
            state.isMode = false; 
        }
    }
});

export const { openNewsletters, finishNewsletters} = newsletterSlice.actions;
export default newsletterSlice.reducer;
