import React, { useState, useEffect, useCallback, memo } from "react";
import styles from './Navbar.module.css';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { IoBagOutline } from "react-icons/io5";
import {  PiListBold } from "react-icons/pi";
import { motion, AnimatePresence } from "framer-motion";
import { GrSearch } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";
import Cart from "../views/Cart";
import { FaInstagram } from "react-icons/fa";

const Navbar = () => {
    const [isOpenCart, setIsOpenCart] = useState(false);
    const [isShowMenuMobile, setIsShowMenuMobile] = useState(false);
    const cartRedux = useSelector(state => state.cartSlice);
    const [renderCountCart, setRenderCountCart] = useState(false);

    const handleOpenCart = useCallback(() => {
        setIsOpenCart(prevState => !prevState);
    }, []);

    useEffect(() => {
        setRenderCountCart(false);
        const timeoutId = setTimeout(() => {
            setRenderCountCart(true);
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [cartRedux.price]);

    const handleShowMenuMobile = useCallback(() => {
        setIsShowMenuMobile(true);
    }, []);

    const handleHideMenuMobile = useCallback(() => {
        setIsShowMenuMobile(false);
    }, []);

    const handleLinkClick = useCallback(() => {
        setIsShowMenuMobile(false);
    }, []);

    return (
        <>
            <Cart isOpen={isOpenCart} setIsOpen={setIsOpenCart} />
            <div className={styles.layout}>
                <div className={styles.menu}>
                    <button onClick={handleShowMenuMobile}>
                        <PiListBold size={22} />
                    </button>
                    <AnimatePresence>
                        {isShowMenuMobile && (
                            <motion.div
                                key="Menu_Nabar_Mobie"
                                initial={{ left: '-100%' }}
                                animate={{ left: 0 }}
                                exit={{ left: '-100%' }}
                                transition={{ duration: 0.4 , ease: 'easeOut' }}
                                className={styles.mobileMenu}
                            >
                                <div>
                                    <button onClick={handleHideMenuMobile}>
                                        <IoCloseSharp size={22} />
                                    </button>
                                </div>
                                <Link onClick={handleLinkClick} to={'/collections/'}>All</Link>
                                <Link onClick={handleLinkClick} to={'/collections/tops'}>Tops</Link>
                                <Link onClick={handleLinkClick} to={'/collections/bottoms'}>Bottoms</Link>
                                <Link onClick={handleLinkClick} to={'/collections/outerwears'}>Outerwears</Link>
                                <Link onClick={handleLinkClick} to={'/collections/accessories'}>Accessories</Link>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div>
                    <Link to="/collections/search">
                        <GrSearch size={20} />
                    </Link>
                </div>
                <div>
                    <button onClick={handleOpenCart} className={styles.saveToCart}>
                        <IoBagOutline size={22} />
                        <motion.span
                            animate={{ scale: renderCountCart ? 1 : 0.85 }}
                            transition={{ duration: 0.3 }}
                        >
                            {cartRedux.count}
                        </motion.span>
                    </button>
                </div>
                <div className={styles.insta}>
                    <a target="_blank" href="https://www.instagram.com/terror.clo/?igsh=MWRqNzd1cjlsb3lmZg%3D%3D&fbclid=IwZXh0bgNhZW0CMTAAAR3kynv6VVDf1JkZxKQDFADf65q88Eigx3cIY8AaApVRxFc0yj8BlnjZijY_aem_ZmFrZWR1bW15MTZieXRlcw">
                        <FaInstagram size={15} /> Insta
                    </a>
                </div>
            </div>
        </>
    );
};

export default memo(Navbar);
