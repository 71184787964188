import React , {useState} from "react";
import styles from './Checkout.Discount.module.css';
import axios from "axios";
import { useDispatch } from "react-redux";
import { discountCart } from "../redux/slices/CartSlice";

const Checkout_DisCount = ({text, language, setValueDiscount })=>{
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const [isDiscount , setIsDiscount] = useState(false);
    const hanldeClick = async ()=>{
        if(value.length > 0){
            try{
                const get = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/client/discount/get-code?value=${value}`, );
                setValueDiscount(get.data);
                dispatch(discountCart({percent: get.data.percent}))
                setIsDiscount(true);
            }catch (error){
                if(error.response.status === 404){
                    language === "vi" ? setValue('// Mã không tồn tại') : setValue('// Code does not exist')
                    setIsDiscount(false);
                    setValueDiscount({codeName: '' , percent: 0});
                    dispatch(discountCart({percent: 0}))
                }
            }

        }
    }

    return(
        <div className={`${styles.layout} ${value.includes('//') && styles.errInput }`}>
            <input  type="text" value={value} onChange={e => setValue(e.target.value)} className={isDiscount ? styles.isDiscount : null} />
            <button onClick= {hanldeClick}>{text}</button>
        </div>
    )
};

export default Checkout_DisCount;
