import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from './SizeGuide.module.css';
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';


const SizeGuide = ({ isOpen, setIsOpen, url }) => {
    const handleClick = (e) => {
        if (!e.target.closest(`.${styles.layout}`)) {
            setIsOpen(false);
        }
    }
    const PORT = process.env.REACT_APP_URL_BACKEND;

    const isMobile  = useMediaQuery({query: '(max-width: 992px)'});
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    key='SizeGuide_Modal'
                    initial={{ 
                        opacity: 0 
                    }}
                    animate={{ 
                        opacity: 1 
                    }}
                    exit={{ 
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.4
                    }}
                    className={styles.overlay}
                    onClick={(e) => handleClick(e)}
                >
                    <div className={styles.layout}>
                        <motion.div
                            key='SizeGuide_Layout'
                            initial={{ x: isMobile ? '-100%' :'100%' }}
                            animate={{ 
                                x: 0 ,
                                transition: {
                                    delay: 0.2 ,
                                    type: 'spring',
                                    stiffness: 67,  
                                    damping: 15
                                }
                            }}
                            exit={{ 
                                x:  isMobile ? '-100%' : '100%' ,
                                transition: {
                                    duration: 0.3,
                                }
                            }}
                            transition={{ duration: 0.4, ease: 'easeOut' }}
                        >
                            <div className={styles.closeBtn}>
                                <button onClick={()=>setIsOpen(false)}><IoClose fill="#747474" size={24} /></button>
                            </div>
                            <img
                                src={`${PORT}/images/${url}/size-guide.jpg`}
                                alt="Size Guide Images | Terror Clothes"
                            />
                        </motion.div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default SizeGuide;
