import React , {useEffect, useState} from "react";
import {useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styles from './LanguageStartPage.module.css';
import Cookies from "js-cookie";
import  { ReactComponent as Logo } from '../assets/logo-outline-02.svg';
import { motion , AnimatePresence, useAnimation } from "framer-motion";
import { SiMaildotru } from "react-icons/si";
import { RiFacebookBoxLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { ReactComponent as LoadingSpinner } from '../assets/loading-spinner-white.svg';
import { ReactComponent as LoadingLogo } from '../assets/logo-white.svg';
import { useSelector  } from "react-redux";


const LanguageStartPage = () =>{
    const navigate = useNavigate();
    const isLoadingStart = useSelector(state => state.appSlice.isLoadingStart);
    const [isLoading, setIsLoading] = useState(isLoadingStart);
    const {  i18n } = useTranslation();
    const handleOption  = (lng)=>{
        i18n.changeLanguage(lng);
        Cookies.set("_cookie-language", lng); 
        navigate('/collections');
    }
    
    const controls1 = useAnimation();
    const controls2 = useAnimation();


    useEffect(() => {
        if(!isLoading){      
            const sequence1 = async () => {
                // Đầu tiên, thay đổi width
                await controls1.start({
                    width: '100vw',
                    transition: { duration: 0.5 }
                });
                // Sau đó, thay đổi top
                await controls1.start({
                    top: '0',
                    transition: { duration: 0.5 }
                });
            };
    
            sequence1();
    
            const sequence2 = async () => {
                // Đầu tiên, thay đổi width
                await controls2.start({
                    width: '100vw',
                    transition: { duration: 0.5 }
                });
                // Sau đó, thay đổi bottom
                await controls2.start({
                    bottom: '0',
                    transition: { duration: 0.5 }
                });
            };
    
            sequence2();
        }{
            const timeoutId = setTimeout(() => {
              setIsLoading(false);
            }, 700);
            
            return () => clearTimeout(timeoutId);
        }
    }, [controls1, controls2, isLoading ]);

   

    return(
        <AnimatePresence>
            {
            isLoading ?
                <motion.div className={styles.loading}
                    key='Loading_Start'
                    animate={{ opacity: 1  }}
                    exit={{  opacity: 0}}
                    transition ={{ duration: 5, ease: 'easeInOut' }}
                >
                    <LoadingLogo />
                <LoadingSpinner />
            </motion.div>
            :
            <div className={styles.layout}>
                <motion.div 
                        className={styles.effect_1}
                        initial={{ 
                            width: 0,
                            top: '50.5%',
                        }}
                        animate={controls1}
                    >
                    </motion.div>
                    <motion.div 
                        className={styles.effect_2}
                        initial={{ 
                            width: 0,
                            bottom: '49.5%',
                        }}
                        animate={controls2}
                    >
                    </motion.div>
                    <motion.div 
                        initial={{  height: 0}}
                        animate={{  height: 500}}
                        transition = {{ duration: 0.5 ,delay: 0.6 }}
                        className={styles.main}
                    >
                        <div className={styles.logoOutline}>
                            <Logo />
                        </div>
                        <div className={styles.container}>
                            <div className={styles.frame_1}>
                            {/* <button onClick={() => handleOption('vi')}>VIỆT NAM</button> */}
                            <button onClick={() => handleOption('vi')}>VIET NAM</button>
                            </div>
                            <div className={styles.frame_2}>
                            <button className={styles.noneActive} onClick={() => handleOption('en')}>INTERNATIONAL</button>
                            </div>
                        </div>
                    </motion.div>
                    <div className={styles.info}>
                            <motion.p
                                initial={{
                                    translateX: 500
                                }}
                                animate={{ 
                                    translateX: 0
                                }}
                                transition={{
                                    delay: 1,
                                    type: "spring",
                                    stiffness: 150,  
                                    damping: 20
                                }}
                            ><SiMaildotru size={12}/>info@terror.com</motion.p>
                            <motion.a
                                initial={{
                                    translateX: 500
                                }}
                                animate={{ 
                                    translateX: 0
                                }}
                                transition={{
                                    delay: 1.1,
                                    type: "spring",
                                    stiffness: 150,  
                                    damping: 20
                                }}
                                href="https://www.instagram.com/terror.clo/?igsh=MWRqNzd1cjlsb3lmZg%3D%3D&fbclid=IwZXh0bgNhZW0CMTAAAR3kynv6VVDf1JkZxKQDFADf65q88Eigx3cIY8AaApVRxFc0yj8BlnjZijY_aem_ZmFrZWR1bW15MTZieXRlcw"
                            ><BsInstagram size={13} />terror.clo</motion.a>
                    </div>
            </div>
            }
        </AnimatePresence>
    )
}

export default LanguageStartPage


