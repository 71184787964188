import React , {useEffect, useState} from "react";
import { Outlet } from "react-router-dom";
import styles from './App.module.css';
import Navbar from "./components/Navbar";
import CookieAccept from "./components/CookieAccept";
import NewSletter from "./components/NewSletter";
import {useLocation } from "react-router-dom";
import Cookies from "js-cookie";


const App = () =>{
    const location = useLocation();
    const [effectHasRun, setEffectHasRun] = useState(false);
    const [isOpenCookie, setIsOpenCookie] = useState(false);
    useEffect(() => {
        if (!effectHasRun) {
            const cookie = Cookies.get('_cookie-acceptCookies');
            if (location.pathname !== '/' && !cookie) {
                setIsOpenCookie(true);
                setEffectHasRun(true);
            }
        }
    }, [effectHasRun, location.pathname]);
    return(
        <div>
            <main className={styles.mainFrame}>
                <Outlet />
                <CookieAccept isOpen={isOpenCookie} setIsOpen={setIsOpenCookie}  />
                <NewSletter />
            </main>
            <div className={styles.navbarFrame}>
                <Navbar/>
            </div>
        </div>
    )
}

export default App