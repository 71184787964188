import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import styles from './PayPal.module.css';
import { useNavigate  } from "react-router-dom";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

const PayPal = ({price })=> {
    const navigate = useNavigate();
    const PORT = process.env.REACT_APP_URL_BACKEND;
    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        // components: "buttons",
        // "data-sdk-integration-source": "developer-studio",
    };

    const [message, setMessage] = useState("");

    return (
        <div className={styles.layout}>
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                style={{
                    shape: "rect",
                    layout: "vertical",
                    color: "gold",
                    label: "paypal",
                }}
                createOrder={async () => {
                    try {
                        const response = await fetch(`${PORT}/api/client/payment/paypal`, {
                        method: "POST",
                        headers: {
                        "Content-Type": "application/json",
                        },
                        // use the "body" param to optionally pass additional order information
                        // like product ids and quantities
                        body: JSON.stringify({
                        cart: [
                            {
                                amount: price
                            },
                            ],
                        }),
                        });

                        const orderData = await response.json();

                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                            : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }
                    } catch (error) {
                        console.error(error);
                        setMessage(`Could not initiate PayPal Checkout...${error}`);
                    }
                }}
                onApprove={async (
                    data,
                    actions
                ) => {
                    try {
                    const response = await fetch(
                        `${PORT}/api/client/payment/paypal/${data.orderID}/capture`,
                        {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        }
                    );

                    const orderData = await response.json();
                    // Three cases to handle:
                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    //   (2) Other non-recoverable errors -> Show a failure message
                    //   (3) Successful transaction -> Show confirmation or thank you message

                    const errorDetail = orderData?.details?.[0];

                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                        return actions.restart();
                    } else if (errorDetail) {
                        throw new Error(
                        `${errorDetail.description} (${orderData.debug_id})`
                        );
                    } else {
                        navigate('success')
                    }
                    } catch (error) {
                    console.error(error);
                    setMessage(
                        `Sorry, your transaction could not be processed...${error}`
                    );
                    }
                }} 
            />
        </PayPalScriptProvider>
        <Message content={message} />
        </div>
    );
}

export default PayPal; 
