import React from "react";
import styles from './ProductList.module.css'
import ProductItem from "./ProductItem";
import axios from 'axios';
import { useLoaderData } from "react-router-dom";
import Cookies from "js-cookie";
import Header from '../components/Header';
import Footer from '../components/Footer';


export const fetchData = async ({ params }) => {
    const { type } = params;
    const mode = (type === undefined) ? 'all' : type;

    const language = Cookies.get('_cookie-language') || 'vi';
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/client/data`, {
            params: {
                language,
                type: mode
            }
        });
        return res.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // or handle the error accordingly
    }
};



const ProductList = () =>{
    const data = useLoaderData();
    return(
        <>
            <Header/>
                <div className={styles.layout}>
                    <div className={styles.container_1}>
                        {
                            data.length === 0 ?
                            <p className={styles.noneData}>Comming soon...</p>
                            :
                            data.map((item, key) => (
                                <ProductItem key={`${item.url} Product Item`} value={item} />
                            ))
                        }
                    </div>
                  
                </div>
            <Footer />
        </>
    )
}

export default ProductList