import React, { useEffect } from "react";
import styles from './CollectionsPage.module.css'
import { Outlet, ScrollRestoration } from "react-router-dom";
import { renderLoadingStart } from "../redux/slices/AppSlice";
import { useDispatch } from "react-redux";

const CollectionsPage = () =>{
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(renderLoadingStart({mode:false}));
    }, []);

    return(
        <div className={styles.layout}>
            <ScrollRestoration
                getKey={(location, matches) => {
                return location.key;
                }}
            />
            <Outlet />
        </div>
    )
}

export default CollectionsPage