import React from "react";
import styles from './Checkout.Payment.module.css';
import paypalLogo from '../assets/paypal-1.png';
import i18n from 'i18next';

const Checkout_Payment = ({ handle, value }) => {
    const currentLanguage = i18n.language;

    const Radio = ({ text, option }) => {
        return (
            <div className={styles.item}>
                <input
                    type="radio"
                    name="methodPayment"
                    id={option}
                    value={option}
                    onChange={e => handle({ 
                        index :  e.target.value,
                        value: text                      
                    })}
                    checked={value.index === option}
                />
                <label htmlFor={option}>
                    {text === "Paypal" && <img src={paypalLogo} alt="Paypal" />}
                    {text}
                </label>


            </div>
        );
    };

    return (
        <>
            <div className={styles.layout}>
                {
                    currentLanguage === "vi" ? (
                        <>
                            <Radio text={'Thanh toán khi nhận hàng (COD)'} option={'option_payment_1'} />
                            <Radio text={'Chuyển khoản ngân hàng'} option={'option_payment_2'} />
                        </>
                        
                    ) : (
                        <>
                            <Radio text={'Paypal'} option={'option_payment_1'} />
                        </>
                    )
                }
            </div>
            {
                value.index === 'option_payment_1' && currentLanguage === "vi" ? 
                <div className={styles.infoBank}>
                    <p>Chúng tôi sẽ liên hệ bạn trong giờ hành chính để xác nhận đơn hàng. <strong>Lưu ý:</strong> nếu bạn không nhận được bất kỳ cuộc gọi nào trong 24 giờ, hãy chủ động liên hệ với chúng tôi qua gmail: info@terror.com</p>
                </div>
                :
                null
            }
            {
                value.index === 'option_payment_2' && currentLanguage === "vi" ? 
                <div className={styles.infoBank}>
                    <p>Chuyển khoản số tiền tương ứng vào tài khoản VIB của chúng tôi. Đơn hàng sẽ được xác nhận ngay sau khi chuyển khoản thành công.</p>
                    <ul>
                        <li>- VIB: <strong>050600777 </strong>- DOAN THUY TIEN</li>
                        <li>- Kèm nội dung: <strong>"Tên của bạn" + "Số điện thoại"</strong>.</li>
                    </ul>
                </div>
                :
                null
            }
        </>
    );
};

export default Checkout_Payment;
