import React , {memo , useState, useEffect } from "react";
import styles from "./Cart.module.css";
import CartItem from "../components/CartItem";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { motion , AnimatePresence } from "framer-motion";
import axios from "axios";
import { renderCart } from "../redux/slices/CartSlice";
import { useMediaQuery } from 'react-responsive';
import i18n from 'i18next';
import Price from "../components/Price";

const Cart = ({isOpen,  setIsOpen }) => {
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartRedux = useSelector(state => state.cartSlice);
    const cartData = cartRedux.data;
    const [isCheckOut, setIsCheckOut]  = useState(false);
    const handleClick = async (e) => {
        if (!e.target.closest(`.${styles.layout}`)) {
            await setIsCheckOut(false);
            setIsOpen(false);
        }
    }
    const isMobile  = useMediaQuery({query: '(max-width: 992px)'});

    useEffect(()=>{
        async function fetchData (){
            const data = Cookies.get('_cookie-cart');
            if(data){
                const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/client/cart?language=${currentLanguage}&data=${data}`);
                let dataRes = res.data ;
                let price = 0;
                let isRender = false;
                dataRes.forEach(item => {
                    if(item.name === '_NotFound'){
                        isRender = true;
                    }else{
                        price += item.number * item.price;
                    }
                });
                dataRes = dataRes.filter(item => item.name !== '_NotFound');
                if(isRender){
                    const newDataCookie = dataRes.map(item =>{
                        return({
                            url: item.url,
                            size: item.size,
                            number: item.number
                        })
                    })
                    Cookies.set('_cookie-cart' ,  JSON.stringify(newDataCookie));
                }

                dispatch(renderCart({
                    data: dataRes,
                    count: dataRes.length,
                    price: price
                }))
            }      
        }
        fetchData();
    },[currentLanguage]);

    const handleCheckOut = async () =>{
            if(isMobile){
                setIsCheckOut(true);
                setIsOpen(false);
            }else{
                await setIsCheckOut(true);
                setIsOpen(false);
            }
        
    };

    const layoutVariants = {
        hidden: { 
            opacity: 0 
        },
        visible: {
            opacity: '100%',
            transition: { delay: 0.2 }
        },
        exit: {
            opacity: isCheckOut? 1 : 0,
            transition: {  delay: isMobile? 0 : 0.2 }
        }
    };
    const frameVariants = {
        hidden: { 
            x: isMobile? '-100%' : '100%' ,
        },
        visible: {
            x:  0 ,
            transition: {   
                delay: 0.2 ,
                type: 'spring',
                stiffness: 67,  
                damping: 15
            }
        },
        exit:{
            x: isCheckOut ? 0 : isMobile? '-100%' : '100%',
            width: isCheckOut ? '100%' : 'inherit',
            opacity: isCheckOut? 0 : 1,
            transition: { 
                 duration :  isCheckOut && !isMobile ?  0.2 : 0.4 
            }
        }
        
    };
   

    return (
        <AnimatePresence onExitComplete={() =>{
            if(isCheckOut){
                navigate('checkout')
            }
         }}>
                {
                    isOpen &&
                    <>
                        <motion.div
                            key='Cart_AfterElement'
                            className={styles.afterElement}
                            initial={{ 
                                backgroundColor: 'rgba(255, 255, 255, 0)',
                                zIndex: '0' 
                            }}
                            exit={{ 
                                backgroundColor: isCheckOut && !isMobile ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)' ,
                                zIndex: '101' 
                            }} 
                            transition={{
                                duration: 0.2,
                                ease: 'easeOut'
                            }}
                        />
                        <motion.div 
                            key='Cart_Model'
                            onClick={(e) => handleClick(e)} 
                            className={styles.overlay}
                            variants={layoutVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                         >
                        
                        <motion.div className={styles.layout}
                                key='Cart_Layout'
                                animate={{
                                    backgroundColor: 'rgba(255, 255, 255, 0)',
                                }}
                                exit= {{
                                    width: isCheckOut &&  '100vw',
                                    backgroundColor: isCheckOut ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)',
                                }}
                                transition= {{
                                    backgroundColor: { duration: 0 },
                                    width: { delay: isMobile? 0 : 0.3, duration:  0.4, ease: 'easeOut' }
                                }}
                            >
                                <motion.div
                                    key="animation-on-state"
                                    variants={frameVariants}
                                    custom={isCheckOut}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                >
                                
                                    <div className={styles.closeBtn}
                                        style={{
                                            borderBottom: cartData.length === 0 ? 'none' : 'var(--frame-boder)' 
                                        }}
                                    >
                                        <button onClick={()=>setIsOpen(false)}><IoClose fill="#747474" size={24} /></button>
                                    </div>
                                    <div className={styles.list}>
                                        {
                                            cartData.map((item,index) => (
                                                <CartItem key={`${item.url}-${item.size}-${index}`} info={item} setIsOpen={setIsOpen}  />
                                            ))
                                        }
                                    </div>
                                    <div className={`${styles.checkout} ${cartData.length === 0 ? styles.noneAcitve : ''}`} >
                                        <div>
                                            <p>{currentLanguage === "vi" ? 'Tổng' : 'Total'} : <Price price={cartRedux.price} /> </p>
                                            <p>{currentLanguage === "vi" ? 'Thuế và tiền vận chuyển tính ở phần thanh toán' : 'Taxes and shipping calculated at checkout.'}</p>
                                        </div>
                                        <div className={styles.checkoutBtn}>
                                            <button onClick={handleCheckOut}>{currentLanguage === "vi" ? 'Thanh toán' :'Check out'}</button>
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </>
                }
        </AnimatePresence>
            
    );
};

export default memo(Cart);
