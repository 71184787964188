import React from "react";
import styles from './Loading.module.css';
import { ReactComponent as LoadingSpinner } from '../assets/loading-spinner.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Loading = () => {

    return (
        <div className={styles.layout}>
            <div>
                <Logo />
                <LoadingSpinner />
            </div>
        </div>
    );
}

export default Loading;